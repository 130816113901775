import * as React from "react";
import "../../App.scss";
import "@/components/Button/button.scss";
import Title from "@/components/Title/Title";
import Col from "@/components/Col/Col";
import Section from "@/components/Section/Section";
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import CookieCompliance from "@/components/CookieCompliance/CookieCompliance";

const TermsOfUse = () => (
  <main>
    <CookieCompliance />
    <Header alwaysFixed></Header>
    <div className="spacerHeaderFixed"></div>

    <Section className="TermsOfUse Textpage" colFlex>
      <Col size="70">
        <Title
          maintitle="Terms of use"
          subtitle="Last Update: September 9th, 2021"
        ></Title>

        <h4>1. AGREEMENT TO TERMS</h4>
        <p>
          These Terms and Conditions constitute a legally binding agreement made
          between you, on behalf of yourself or an entity (“Customer”, “User”
          “you”, “your”) and ecomtrack s.r.o., (“ecomtrack”, “Company”, “we”,
          “us”). These Terms and Conditions govern your use of the website at
          www.ecomtrack.io, and any other associated web or mobile websites, or
          media channel (hereinafter the “Website” or “Websites”), and you use
          of any services that you use and that are provided by ecomtrack
          (collectively “Service” and “Services”). You agree that by accessing
          our Websites and using the Services, you have read, understood and
          agreed to be bound by these Terms and Conditions. If you do not agree
          with the Terms and Conditions, you should stop using the Websites and
          Services. Supplemental terms and conditions and other documents may be
          posted on our Websites and are expressly incorporated by reference. We
          reserve to the right, in our sole discretion, to make changes and
          updates to these Terms and Conditions, and at any time and for any
          reason. We encourage you to periodically review these Terms and
          Conditions and stay informed of any updates. We will inform you of any
          material changes to these Terms and Conditions using the contact
          information and methods you have provided to us. You will be subject
          to, and will deem to have been made aware of any changes to these
          Terms and Conditions by your continued use of the Websites and
          Services. You affirm that you are more than 18 years of age, and are
          fully able and competent to enter into the terms, conditions,
          obligations, affirmations, representations, and warranties set forth
          in these Terms and Conditions, and to abide by and comply with these
          Terms and Conditions. The Services are exclusively for the use of
          adults 18 years of age or older, and if you are below the age of 18
          you are prohibited from using the Services.
        </p>

        <h4>2. DEFINITIONS</h4>
        <p>
          As used in these Terms and Conditions, the following defined terms
          shall apply:
          <li>
            2.1. Customer Sites means website, webpages, applications, landing
            pages, or any other media provided to or integrated with the
            Services, for the purposes of enabling the Company to provide the
            Services.
          </li>
          <li>
            2.2. Effective Date means the earlier of the date (i) Customer
            submits the initial Order, or (ii) as stated on the signature block
            of the Order.
          </li>
          <li>
            2.3. Order or Services Order means any initial or subsequent
            ordering document and/or online request for access to the Services
            submitted to Company, an authorized reseller of Company and/or
            through Company’s product websites.
          </li>
          <li>
            2.4. Service Descriptions means the overview and other terms
            applicable to the Services, as amended from time to time.
          </li>
          <li>
            2.5. Updates means any corrections, bug fixes, new features or
            functions added to the Services, but shall not include any new
            versions that Company markets and sells separately.
          </li>
          <li>
            2.6. Use Level means the model by which Company measures, prices and
            offers the Service to Customer as set forth on the applicable price
            list, websites, Order, and/or Service Description.
          </li>
          <li>
            2.7. Mark of Company or Company Marks shall mean and names, logos,
            icons, images, branding, or any other associated content or media
            used to identify the Company.
          </li>
        </p>

        <h4>3. RIGHTS AND LICENSE</h4>
        <p>
          <li>
            3.1. Right to Use Service. Subject to these Terms and Conditions,
            Company will provide the Services set forth in the Order for
            Customer’s use in accordance with these Terms and Conditions and
            applicable Use Levels. Company hereby grants to Customer a limited,
            personal, non-exclusive, non-transferable, non-sublicensable right
            to use any components as may be required to access and use the
            Services. Customer agrees to use the Services in accordance with the
            Company’s Privacy Policy, available at
            https://ecomtrack.io/privacy-policy/ and incorporated by reference
            (the “Privacy Policy”)
          </li>
          <li>
            3.2. Technical Support. Technical support for the Services is
            provided as set forth in the applicable Service Description.
            Customer agrees to use the Services for professional or business
            use. Company reserves the right to Update the Services at its
            discretion. Updates to the Services are included in the Fees, and
            Customer agrees to use the most current version of the Service.
          </li>
        </p>

        <h4>4. INTELLECTUAL PROPERTY RIGHTS</h4>
        <p>
          <li>
            4.1. Proprietary Rights. Except for the limited subscription rights
            granted herein, Customer has no right, title or interest in or to
            the Company Marks or Services or any components provided by Company
            in connection with the Services or any intellectual property rights
            related thereto. Customer acknowledges that Company or its licensors
            retain all proprietary right, title and interest in and to, or
            practiced in connection with, the Company Marks and the Services and
            any components, including, without limitation, all modifications,
            enhancements, derivative works, configuration, translations,
            upgrades, and interfaces thereto.
          </li>
          <li>
            4.2. Company’s Marks. Unless expressly authorized under the terms of
            this Agreement, Customer agrees that it shall not use, register or
            apply for registration of any trademark, service mark, business
            name, company/trade name, domain name or social media account name
            or handle which is comprised of or incorporates in whole or in part
            any Mark of Company, or is otherwise confusingly similar to a Mark
            of Company. In the event of any breach of this provision, Customer
            agrees that it will do all things necessary to effect the transfer
            of any such same or similar trademark, service mark, business name,
            company/trade name, domain name or social media account name or
            handle to Company, including but not limited to executing assignment
            documentation. Except as expressly granted herein, no license
            regarding the use of Company’s copyrights, patents, trademarks,
            service marks or company/trade names is granted or will be implied.
            For any authorized use of the Company’s Marks, Customer represents
            that it has reviewed and will adhere to Company’s Trademark &
            Copyright Guidelines, and incorporated herein by reference and as
            may be periodically updated by Company. As noted above,
            reproduction, copying, or redistribution for commercial purposes of
            any materials or design elements on the Website or Services is
            strictly prohibited without the express written permission of
            Company. For information on requesting such permission, please
            contact us at helpdesk@ecomtrack.io
          </li>
          <li>
            4.3. You agree that any ideas, suggestions, or improvements that you
            provide to Company about Company’s products or services shall be
            owned by Company and that Company is free to include such ideas in
            future products without compensation to you.
          </li>
        </p>

        <h4>5. ORDERS, FEES, AND PAYMENT</h4>
        <p>
          <li>
            5.1. Orders. Customer may order Services using the Company’s
            then-current ordering processes. All Orders are subject to
            acceptance by Company in its discretion. All Customer information
            provided by or on behalf of Customer must be current, complete and
            accurate and Customer is responsible for keeping such information
            updated. Order information is subject to automatic processing by
            Company for the purposes of managing Customer’s account.
          </li>
          <li>
            5.2. Fees and Payment. Customer is responsible for all fees
            applicable to the Services, including any one-time implementation
            fees (“Fees”). All Fees are due and payable as set forth on the
            invoice and, unless otherwise agreed in writing, payments are due
            thirty (30) days from the invoice date. Customer agrees to notify
            Company of any fee dispute within fifteen (15) days of the invoice
            date and Customer agrees to work in good faith to promptly resolve
            any dispute and pay fees within fifteen (15) days following
            resolution of the dispute. When applicable, Customer authorizes
            Company (i) to take steps to determine whether a debit/credit card
            number provided is valid, and (ii) charge such card in accordance
            with the billing frequency specified in the Order. Company reserves
            the right to terminate its agreement with Customer immediately in
            the event any payment information is found at any time to be
            inaccurate, incomplete and/or not current. Company shall not be
            responsible for any overdraft charges or other fees that may be
            incurred due to Company use of Customer’s card for payment
            hereunder. Company reserves the right to update the price for
            Services at any time after the Initial Term. Company will notify
            Customer of any price changes by publishing on its website,
            emailing, quoting or invoicing Customer. Price changes will be
            effective as of the next billing cycle.
          </li>
          <li>
            5.3. Additional Services. Customer may order additional Services at
            any time. Unless otherwise agreed in the applicable Order, any
            additional Services ordered by Customer following the Effective Date
            are subject to these Terms and Conditions, and shall be coterminous
            with the Terms and Conditions for existing Services.
          </li>
          <li>
            5.4. Late Payments. Company reserves the right, in its discretion,
            to (i) suspend or terminate the Services or any portion thereof for
            non-payment of undisputed Fees, and (ii) impose a charge to restore
            archived data from delinquent accounts. Customer agrees to reimburse
            Company for all reasonable costs and expenses incurred in collecting
            delinquent amounts.
          </li>
          <li>
            5.5. Taxes and Withholding. Customer shall be responsible for all
            applicable taxes (including but not limited to withholding tax,
            sales tax, services tax, value-added tax (VAT), goods and services
            tax (GST), tariffs, Universal Services Fund (USF) fees
            (collectively, “Taxes”) imposed by any government entity or
            collecting agency based on the Services, except those Taxes based on
            Company net income, and/or those Taxes for which Customer has
            provided a certificate confirming Customer is otherwise exempt. If
            Customer fails to satisfy its Tax obligations herein, Customer
            agrees to reimburse Company for any Taxes paid on Customer’s behalf
            and indemnify and hold Company harmless against any claim, liability
            and/or penalties resulting therefrom.
          </li>
        </p>

        <h4>6. CUSTOMER ACCOUNTS AND USAGE</h4>
        <p>
          <li>
            6.1. Customer is solely responsible for (i) the configuration of
            Customer’s Services account, (ii) the operation, performance, and
            security of Customer’s equipment, networks and other computing
            resources used to connect to the Services, (iii) ensuring all users
            exit or log off from the Services at the end of each session, (iv)
            maintaining the confidentiality of Customer’s accounts, user id’s,
            and passwords used in conjunction with the Services, and (v) all
            uses of the Services by Customer and its users. Company reserves the
            right to suspend the Services or terminate its agreement with
            Customer if Customer misuses or otherwise shares login information
            among users. Customer will notify Company immediately of any
            unauthorized use of its account or any other breach of security.
            Company will not be liable for any loss that Customer may incur as a
            result of a third party using its password or account, and Customer
            may be held liable for any such losses incurred by Company and/or
            another party. Company reserves the right to review Customer’s
            account to confirm compliance with applicable Use Levels, and to
            terminate or suspend Customer’s access for overuse and/or misuse.
            Customer agrees to pay for any overage in excess of permitted Use
            Levels.
          </li>
          <li>
            6.2. You understand and agree not to provide false information about
            yourself, to impersonate another individual or provide misleading or
            false user information through your use of the Website or Services.
          </li>
        </p>

        <h4>7. PROHIBITED ACTIVITIES AND RESTRICTIONS</h4>
        <p>
          <li>
            7.1. You affirm, represent, and warrant that, through your use on
            the Services, Any Customer Sites do not relate to the following:
            Pornography or sexually explicit content The occult Hate speech
            Racism Pharmaceuticals Purchase and use of illegal narcotics
            Medicinal and recreational cannabis products Cryptocurrency Illegal
            activities of any kind You also represent and warrant the content
            contained or distributed by Customer Sites does not violate the
            intellectual property rights of third parties. Company reserves the
            right to make the final decision regarding what is appropriate.
          </li>
          <li>
            7.2. The Services are for professional/business use only, and may
            not be used for any other purpose.
          </li>
          <li>
            7.3. You understand and agree to not place an unreasonable burden on
            the server hosting the Website or the Services, and to not interfere
            with the running of the Website or Services, and to not attempt
            unauthorized access to any portion of the Website.
          </li>
          <li>
            7.4. You agree not to use bots, click-farms, fake click-throughs, or
            any other automated or manual techniques to fake, simulate or
            otherwise generate user activity on the Websites, the Service or
            Customer Sites with the intention of creating exaggerated or
            misleading user activity.
          </li>
          <li>
            7.5. Except to the extent permitted by applicable law, Customer
            agrees, on behalf of itself and its users, not to (i) modify,
            distribute, prepare derivative works of, reverse engineer, reverse
            assemble, disassemble, decompile or attempt to decipher any code
            relating to the Websites, Services, and/or Company’s technology;
            (ii) knowingly or negligently access or use the Websites or Services
            in a manner that abuses or disrupts the Company’s networks, security
            systems, user accounts, or Services of Company or any third party,
            or attempt to gain unauthorized access to any of the above through
            unauthorized means, (iii) market, offer to sell, and/or resell the
            Services to any unauthorized third party; (iv) use the Services in
            violation of Company policies, applicable laws, ordinances or
            regulations; (v) use the Websites or Services directly or indirectly
            for competitive benchmarking or other competitive analysis if
            Customer is a direct competitor of the applicable Service; or (vi)
            make a ny representations with respect to Company or these Terms and
            Conditions (including, without limitation, that Company is a
            warrantor or co-seller of any of Customer’s products and/or
            services). Company shall have sole and exclusive discretion to
            determine the applicability of the restrictions set forth above and
            any violations thereof.
          </li>
          <li>
            7.6. Company further reserves the right to suspend or terminate your
            use of the Websites and Services without prior notice for a
            violation of any of the provisions in this section.
          </li>
        </p>

        <h4>8. PRIVACY</h4>
        <p>
          Please read our Privacy Policy available at:
          https://ecomtrack.io/privacy-policy to carefully to understand how
          Company collects, uses and discloses personally identifiable
          information from its users. The Privacy Policy is hereby incorporated
          by reference into these Terms and Conditions.
        </p>

        <h4>9. THIRD-PARTY SITES</h4>
        <p>
          The Website and Services may link you to other sites on the Internet.
          These sites may contain information or material that some people may
          find inappropriate or offensive. These other sites are not under the
          control of Company, and you acknowledge that Company is not
          responsible for the accuracy, copyright compliance, legality, decency,
          or any other aspect of the content of such sites. The inclusion of
          such a link does not imply endorsement of the site by or any
          association with its operators.
        </p>

        <h4>10. COMPLIANCE WITH LAWS</h4>
        <p>
          In connection with the performance, access, and use of the Services
          under these Terms and Conditions, Company and Customer agrees to
          comply with all applicable laws, rules and regulations including, but
          not limited to, export and import, data protection, and privacy laws
          and regulations. Specifically, Customer shall provide the relevant
          persons and/or participants with all information or notices Customer
          is required by applicable privacy and data protection laws to provide
          and, if necessary, obtain the consent of or provide choices to such
          persons and/or participants as required. Notwithstanding any other
          provision in these Terms and Conditions, Company shall have the right
          to immediately terminate its agreement with Customer for noncompliance
          with applicable laws.
        </p>

        <h4>11. DISCLAIMER OF WARRANTIES</h4>
        <p>
          ALL MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED
          IN OR AVAILABLE THROUGH THE WEBSITE AND SERVICES (THE “CONTENT”) ARE
          PROVIDED “AS IS” AND “AS AVAILABLE” FOR YOUR USE. THE CONTENT IS
          PROVIDED WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, OR NON INFRINGEMENT. COMPANY AND ITS
          AGENTS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
          CORRECT; THAT THE WEBSITE OR SERVICES WILL BE AVAILABLE AT ANY
          PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE
          CORRECTED; OR THAT THE CONTENT IS FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS. YOUR USE OF THE WEBSITE AND SERVICES IS SOLELY AT YOUR
          RISK. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF
          CERTAIN WARRANTIES, THESE EXCLUSIONS MAY NOT APPLY TO YOU.
        </p>

        <h4>12. LIMITATION OF LIABILITY</h4>
        <p>
          UNDER NO CIRCUMSTANCES SHALL COMPANY OR ITS AGENTS BE LIABLE FOR ANY
          DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
          DAMAGES THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THE WEBSITE
          AND SERVICES. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS
          BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER
          BASIS, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, COMPANY’S LIABILITY
          IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.
          Any provision herein to the contrary notwithstanding, the maximum
          liability of Company to any person, firm or corporation whatsoever
          arising out of or in the connection with any license, use or other
          employment of any Services delivered to You hereunder, whether such
          liability arises from any claim based on breach or repudiation of
          contract, warranty, tort or otherwise, shall in no case exceed the
          actual price paid to Company by You for the Services whose license,
          use, or other employment gives rise to the liability. The essential
          purpose of this provision is to limit the potential liability of
          Company arising out of this Agreement. The parties acknowledge that
          the limitations set forth in this section are integral to the amount
          of consideration levied in connection with the Website, the Services,
          and any services rendered hereunder and that, were Company to assume
          any further liability other than as set forth herein, such
          consideration would of necessity be set substantially higher.
        </p>

        <h4>13. INDEMNIFICATION</h4>
        <p>
          Upon a request by Company, you agree to defend, indemnify, and hold
          Company and its other affiliated companies harmless, and their
          employees, contractors, officers, and directors from all liabilities,
          claims, and expenses, including attorney’s fees, that arise from your
          misuse of the Website or Services
        </p>

        <h4>14. ADDITIONAL TERMS</h4>
        <p>
          <li>
            14.1. Services Trial. Company may make the Services available to
            Customer on a trial basis or offer promotional versions of the
            Services for a limited period of time (“Trial Period”), as specified
            on the applicable Order. The Trial Period shall terminate (i) at the
            end of the stated Trial Period, or (ii) if no such date is
            specified, thirty (30) days from the date of Customer’s initial
            access to the Services. Following expiration of the Trial Period,
            the Services may automatically continue unless canceled by Customer,
            and Customer is responsible for payment of the applicable Fees set
            forth in the Order. During the Trial Period, Company provides the
            Services “AS IS” and without warranty or indemnity, to the extent
            permitted by law, and all other terms of these Terms and Conditions
            otherwise apply. Company reserves the right to modify or discontinue
            any trials or promotions at any time without notice.
          </li>
          <li>
            14.2. Copyright. In the event Customer believes that the Services
            have been used in a manner that constitutes copyright infringement,
            Customer shall notify Company at helpdesk@ecomtrack.io, and provide
            all of the following information, as required by the Digital
            Millennium Copyright Act ("DMCA"): (i) a statement that Customer has
            identified content in the Services that infringes a copyright of a
            third party for whom Customer is authorized to act; (ii) a
            description of the copyrighted work Customer claims has been
            infringed; (iii) a specific description of where the allegedly
            infringing material is located in the Services, including a URL or
            exact description of the content's location; (iv) Customer’s name,
            address, telephone number, and e-mail address; (v) a statement that
            Customer has a good faith belief that the disputed use of the
            copyrighted material is not authorized by the copyright owner, its
            agent, or the law (e.g., as a fair use); (vi) a statement that,
            under penalty of perjury, the information in Customer’s notice is
            accurate and that Customer is authorized to act on behalf of the
            owner of the exclusive right that is allegedly infringed; and (vii)
            Customer’s electronic or scanned physical signature. Company
            reserves the right to delete or disable allegedly infringing
            content, to terminate the accounts of users who are repeat
            infringers, and to forward the information in the
            copyright-infringement notice to the user who allegedly provided the
            infringing content.
          </li>
          <li>
            14.3. Cookies. By using the Services or websites, Customer agrees to
            the use of cookies which Company uses to facilitate use of the
            Services and the Website. Company does not store passwords or any
            other Customer personal information in the cookies.
          </li>
          <li>
            14.4. Suspension of Service. Company may temporarily suspend the
            Services if Company determines, in its sole discretion, that
            continued provision would compromise the security of the Services
            due to, without limitation, hacking attempts, denial of service
            attacks, mail bombs or other malicious activities, and Company will
            take action to promptly resolve any such security issues. Company
            agrees to notify Customer of any such suspension and subsequent
            reactivation of the Services.
          </li>
          <li>
            14.5. Assignment. Neither party may assign its rights or delegate
            its duties under these Terms and Conditions either in whole or in
            part without the other party’s prior written consent, which shall
            not be unreasonably withheld, except that either party may assign
            these Terms and Conditions as part of a corporate reorganization,
            consolidation, merger, or sale of all or substantially all of its
            assets. Any attempted assignment without such consent shall be void.
            These Terms and Conditions will bind and inure to the benefit of
            each party’s successors or assigns.
          </li>
          <li>
            14.6. Notices. All legal notices required under these Terms and
            Conditions shall be in writing and delivered in person or by
            certified or registered express mail to the address last designated
            on the account for Customer, and the Company contracting entity as
            specified below, or such other address as either party may specify
            by notice to the other party as provided herein. Notice shall be
            deemed given (i) upon personal delivery; (ii) if delivered by air
            courier or email, upon confirmation of receipt; or (iii)five (5)
            days after deposit in the mail. Non-legal notices may be provided to
            the email address specified on the applicable Order and shall be
            deemed effective on the next business day following the date and
            time stamp on the sender’s email. Company may also provide Customer
            with notice postings on the Company Website.
          </li>
          <li>
            14.7. Entire Agreement; Order of Precedence. These Terms and
            Conditions set forth the entire agreement and understanding of the
            parties relating to the Services and supersedes all prior and
            contemporaneous oral and written agreements. For any conflict
            between an executed Order, these Terms and Conditions and the
            Service Descriptions, the conflict shall be resolved in that order,
            but only for the specific Services described in the applicable
            Order. Nothing contained in any document submitted by Customer shall
            in any way add to or otherwise modify these Terms and Conditions or
            Company’s program terms.
          </li>
          <li>
            14.8. General Terms. Captions and headings are used herein for
            convenience only, are not a part of these Terms and Conditions, and
            shall not be used in interpreting or construing these Terms and
            Conditions. The parties are independent contractors and nothing in
            these Terms and Conditions creates a partnership, franchise, joint
            venture, agency, fiduciary or employment relationship between or
            among the parties. No person or entity not a party to these Terms
            and Conditions will be deemed to be a third-party beneficiary of
            these Terms and Conditions or any provision hereof. Company
            authorized resellers and distributors do not have the right to make
            modifications to these Terms and Conditions or to make any
            additional representations, commitments, or warranties binding on
            Company. No waiver or amendment of any term or condition of these
            Terms and Conditions shall be valid or binding on any party unless
            agreed to in writing by Company or Customer. Company failure to
            enforce any term of these Terms and Conditions will not be construed
            as a waiver of the right to enforce any such terms in the future.
            Unless otherwise specified, remedies are cumulative. These Terms and
            Conditions may be agreed to online, or executed by electronic
            signature and in one or more counterparts. No party will be
            responsible for any delay, interruption or other failure to perform
            under these Terms and Conditions due to force majeure events and
            acts beyond a party’s reasonable control, but only for so long as
            such conditions persist. Force majeure events may include: natural
            disasters; wars; terrorist activities, activities of local exchange
            carriers, telephone carriers, wireless carriers, and Internet
            service providers, labor disputes; and acts of government.
          </li>
        </p>

        <h4>15. SEVERABILITY AND INTEGRATION</h4>
        <p>
          Unless otherwise specified herein, this Agreement constitutes the
          entire agreement between you and Company with respect to the Website
          and Services and supersedes all prior or contemporaneous
          communications between you and Company with respect to the Website and
          Services. If any part of these Terms and Conditions is held invalid or
          unenforceable, that portion shall be construed in a manner consistent
          with applicable law to reflect, as nearly as possible, the original
          intentions of the parties, and the remaining portions shall remain in
          full force and effect.
        </p>

        <h4>16. GOVERNING LAW AND JURISDICTION</h4>
        <p>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of the Czech Republic. You hereby consent to
          binding arbitration in the Czech Republic to resolve any disputes
          arising under these Terms and Conditions.
        </p>

        <h4>17. ARBITRATION OF DISPUTES</h4>
        <p>
          The parties agree that any dispute or claim in law or equity arising
          between them regarding the use of the Website, the Services, or these
          Terms and Conditions, including any dispute regarding the
          enforceability or applicability of this arbitration provision, shall
          be decided by neutral, binding arbitration conducted in the Czech
          Rpeublic. The arbitrator shall be a retired judge, justice, or an
          attorney with at least ten (10) years of legal experience relating to
          the subject matter of this Agreement, unless the parties mutually
          agree otherwise, who shall render an award in accordance with the
          substantive laws of the Czech Republic. In all other respects, the
          arbitration shall be conducted in accordance with the rules and
          procedures of the American Arbitration Association, subject to the
          parties being allowed limited discovery. Judgment upon the award of
          the arbitrator(s) may be entered in any court having jurisdiction.
          NOTICE: BY USING THE WEBSITE AND SERVICES YOU ARE AGREEING TO HAVE ANY
          DISPUTE ARISING OUT OF THE MATTERS INCLUDED IN THIS “ARBITRATION OF
          DISPUTES” PROVISION DECIDED BY NEUTRAL ARBITRATION AND YOU ARE GIVING
          UP ANY RIGHTS YOU MIGHT POSSESS TO HAVE THE DISPUTE LITIGATED IN A
          COURT OR JURY TRIAL. YOU ARE GIVING UP YOUR JUDICIAL RIGHTS TO
          DISCOVERY AND APPEAL, UNLESS THOSE RIGHTS ARE SPECIFICALLY INCLUDED IN
          THE “ARBITRATION OF DISPUTES” PROVISION. IF YOU REFUSE TO SUBMIT TO
          ARBITRATION AFTER AGREEING TO THIS PROVISION YOU MAY BE COMPELLED TO
          ARBITRATE ANYHOW PURSUANT TO A COURT ORDER. YOUR AGREEMENT TO THIS
          ARBITRATION PROVISION IS VOLUNTARY. IF YOU DO NOT WISH TO AGREE TO
          ARBITRATION, THEN YOU MAY NOT USE THE WEBSITES OR SERVICES.
        </p>

        <h4>18. CLASS ACTION WAIVER</h4>
        <p>
          ANY LEGAL ACTION OR ARBITRATION ARISING IN CONNECTION WITH THE USE OF
          THE WEBSITE, THE SERVICES, OR THESE TERMS AND CONDITIONS MUST BE ON AN
          INDIVIDUAL BASIS. THIS MEANS NEITHER YOU NOR WE MAY JOIN OR
          CONSOLIDATE CLAIMS BY OR AGAINST OTHER CUSTOMERS, OR LITIGATE IN COURT
          OR ARBITRATE ANY CLAIMS AS A REPRESENTATIVE OR MEMBER OF A CLASS OR IN
          A PRIVATE ATTORNEY GENERAL CAPACITY.
        </p>

        <h4>19. ATTORNEYS’ FEES</h4>
        <p>
          In any dispute, action, proceeding, or arbitration regarding the use
          of the Website, the Services, or these Terms and Conditions, including
          the enforcement of any arbitration provision herein, the party
          prevailing in such action or proceeding shall be entitled to recover,
          in addition to any other award of damages or other remedies, its
          reasonable attorneys’ and experts’ fees, costs and expenses
          (including, without limitation, expenses for expert witnesses and all
          reasonable attorneys’ fees, costs and expenses upon appeal).
        </p>

        <h4>20. ABOUT US</h4>
        <p>Our Websites and Services are provided by:</p>
        <p>Ecomtrack s.r.o.</p>
        <p>K Pasekám 2984/45 76001 Zlín</p>
        <p>Czech Republic</p>
        <p>helpdesk@ecomtrack.io</p>
      </Col>
    </Section>

    <Footer colFlex></Footer>
  </main>
);

export default TermsOfUse;
